import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FooterAlternate1, FooterAlternate3 } from "../../components/footer"
import Share from "../../components/share"
import SEO from "../../components/seo"

const IndexPage = ({ location }) => (
    <Layout location={location}>
            <SEO title="Companies" />

            <nav className="share-bar">
                <ul>
                    <li>
                        <Link to="/enquiries/" className="btn btn-default">Book an assessment</Link>
                    </li>
                </ul>
            </nav>
            <article className="article">
                <header className="header header--primary article__header">
                    <div className="header__image">
                        <div className="header__bgimage" style={{backgroundImage:"url(/media/svg/solution-header_startups.svg)"}}></div>
                    </div>
                    <div className="header__content">
                        <div className="container">
                            <div className="row">
                                <div className="header__inner col-sm-9 col-md-7 col-lg-6 col-lg-offset-1">
                                    <Link to="/solutions/" className="header__back back-link">
                                        <span className="sr-only">Back to all articles</span>
                                        <i className="icon"></i>
                                    </Link>
                                    <div className="header__title">
                                        <h3>Solutions</h3>
                                        <h1>Companies</h1>
                                    </div>
                                    <h3 className="header__caption">Are you trying to accelerate sales, but going nowhere?</h3>
                                    <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container" id="article-content">
                    <div className="row">
                        <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7 large">
                            <h3>SalesTribe is your best-in-class partner for digital sales transformation</h3>
                            <div className="rich-text">
                                <p>Most small businesses continue to struggle with finding, implementing and managing high cost sales operations, and very few small businesses can assume the high risks of recruiting, training and retaining expensive B2B sales professionals...especially when average sales tenure is now 16.8 months. </p>
                                <p>SalesTribe helps businesses by providing access to best-fit sales talent to help accelerate growth, reduce 'time-to-revenue' and to shift <i>fixed</i> costs to <i>variable</i> costs.
                                    <br/>
                                </p>
                                <p>Register with SalesTribe today to find out how we can help you accelerate the growth of your revenue streams. </p>
                            </div>
                        </div>
                        <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
                            <div className="article__sidebar-wrap" id="article-sidebar">
                                <h4>Assessments from:</h4>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <h2 className="list-group-item-heading">Go-2-Market Assessment - free!</h2>
                                        <p className="list-group-item-text">Digital Sales Transformation</p>

                                    </li>
                                </ul>
                                <div className="article__sidebar-btns">
                                    <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                                    <Link to="/enquiries/" className="btn btn-link">Contact an advisor</Link>
                                </div>
                                <Share />
                            </div>
                        </aside>
                    </div>
                </div>
            </article>
            <div className="block block--base benefits--small benefits">
                <div className="container">
                    <div className="block__header row">
                        <div className="col-sm-12 col-md-offset-1 col-md-10">
                            <h2>Solution to get your business ready</h2>
                            <div className="block__caption">
                                <h3>We tailor solutions to you, understanding what your career needs to get you ready to work with a new business.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                    <div className="block__footer row">
                        <div className="col-sm-12 col-md-offset-1 col-md-10">
                            <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block block--base block--ruledtop how-it-works">
                <div className="container">
                    <div className="block__header row">
                        <div className="col-sm-offset-1 col-sm-10">
                            <h2>How it works</h2>
                        </div>
                    </div>
                    <div className="how-it-works__wrap row">
                        <div className="how-it-works__svg container">
                            <div className="how-it-works__svgwrap">
                                <div className="hidden-xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 270" width="1133" height="270" preserveAspectRatio="xMinYMin">
                                        <defs>
                                            <clipPath id="landingMaskA">
                                                <path d="M0 0h500v270H0zM500 0h133v30H500zM633 0h500v270H633zM500 240h133v30H500z" />
                                            </clipPath>
                                        </defs>
                                        <g clipPath="url(#landingMaskA)">
                                            <path fill="none" stroke="#e1e1e1" strokeWidth="10" strokeMiterlimit="10" d="M155 135h160a50 50 0 0 0 50-50V55a50 50 0 0 1 50-50h110a40 30 0 0 1 40 30v200a40 30 0 0 0 40 30h110a50 50 0 0 0 50-50v-40a50 50 0 0 1 50-50h160" />
                                            <path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M155 135h160a50 50 0 0 0 50-50V55a50 50 0 0 1 50-50h110a40 30 0 0 1 40 30v200a40 30 0 0 0 40 30h110a50 50 0 0 0 50-50v-40a50 50 0 0 1 50-50h160" />
                                        </g>
                                    </svg>
                                </div>
                                <div className="visible-xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 800" width="768" height="800" preserveAspectRatio="xMinYMin">
                                        <path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M500 100h300m0 225H409a25 25 0 0 0-25 25m0 220v40" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row">
                                <Link to="/how-it-works/sales-professionals/" className="how-it-works__item col-xs-12 col-sm-4">
                                    <img src="/media/svg/landing-256x183_sales-professionals.svg" alt="" />
                                    <h3 className="how-it-works__title">In between roles? Looking for a new sales role?</h3>
                                    <p className="how-it-works__caption">SalesTribe works with sales people whom are now ready for change......</p>
                                    <button className="btn btn-link">Solutions for sales people</button>
                                </Link>
                                <div className="how-it-works__item middle col-xs-12 col-sm-4">
                                    <img src="../../static/images/svg/landing-256x183_brand.svg" alt="" />
                                    <h3 className="how-it-works__title">Connect through SalesTribe</h3>
                                    <p className="how-it-works__caption">Providing sales people job readiness and connections to suitable businesses</p>
                                </div>
                                <Link to="/how-it-works/start-businesses/" className="how-it-works__item col-xs-12 col-sm-4">
                                    <img src="/media/svg/landing-256x183_startups.svg" alt="" />
                                    <h3 className="how-it-works__title">Does your business need to change how you sell?</h3>
                                    <p className="how-it-works__caption">SalesTribe specialises in digital sales transformation</p>
                                    <button className="btn btn-link">Solutions for companies</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="block__footer row">
                        <div className="col-sm-12 col-md-offset-1 col-md-10">
                            <Link to="/how-it-works/" className="btn btn-link">Learn more</Link>
                        </div>
                    </div>
                </div>
            </div>

            <FooterAlternate3 />      
            <FooterAlternate1 />

        </Layout>
)

export default IndexPage